import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import useMapContext from "../Map/useMapContext";
import { useMapServerQueryData } from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";
import { COMMUNICATION_TOWERS, CommunicationTowersId } from "./constants";
import { addMapSource } from "./utils";

interface CommunicationTowersLayerProps {
  opacity?: number;
}

const CommunicationTowersLayer = ({
  opacity = 1,
}: CommunicationTowersLayerProps) => {
  const map = useMapContext();

  const { data: rfsPagingData } = useMapServerQueryData(
    COMMUNICATION_TOWERS[CommunicationTowersId.RFS_PAGING].dataUrl,
  );
  const { data: rfsPmrData } = useMapServerQueryData(
    COMMUNICATION_TOWERS[CommunicationTowersId.RFS_PMR].dataUrl,
  );
  const { data: abcLocalRadioData } = useMapServerQueryData(
    COMMUNICATION_TOWERS[CommunicationTowersId.ABC_LOCAL_RADIO].dataUrl,
  );

  useEffect(() => {
    const controller = new AbortController();

    Object.entries(COMMUNICATION_TOWERS).forEach(([id]) => {
      addMapSource(map, id);

      loadImage({
        imageId: id,
        map,
        src: COMMUNICATION_TOWERS[id as CommunicationTowersId].imageUrl,
        signal: controller.signal,
      }).then(() => {
        map.addLayer(
          {
            id,
            type: "symbol",
            source: id,
            layout: {
              "icon-image": id,
              "icon-allow-overlap": true,
              "icon-optional": false,
            },
          },
          MapLevel.SYMBOLS,
        );
      }, catchAbortError);
    });

    return () => {
      controller.abort();
      Object.keys(COMMUNICATION_TOWERS).forEach((id) => {
        map.removeLayer(id);
        map.removeSource(id);
        if (map.hasImage(id)) {
          map.removeImage(id);
        }
      });
    };
  }, [map]);

  useEffect(() => {
    const rfsPagingSource = map.getSource(CommunicationTowersId.RFS_PAGING);
    if (isGeoJsonSource(rfsPagingSource) && rfsPagingData) {
      rfsPagingSource.setData(rfsPagingData);
    }

    const rfsPmrSource = map.getSource(CommunicationTowersId.RFS_PMR);
    if (isGeoJsonSource(rfsPmrSource) && rfsPmrData) {
      rfsPmrSource.setData(rfsPmrData);
    }

    const abcLocalRadioSource = map.getSource(
      CommunicationTowersId.ABC_LOCAL_RADIO,
    );
    if (isGeoJsonSource(abcLocalRadioSource) && abcLocalRadioData) {
      abcLocalRadioSource.setData(abcLocalRadioData);
    }
  }, [rfsPagingData, rfsPmrData, abcLocalRadioData, map]);

  useEffect(() => {
    Object.keys(COMMUNICATION_TOWERS).forEach((id) => {
      map.setPaintProperty(id, "icon-opacity", opacity);
    });
  }, [map, opacity]);

  return null;
};

export default CommunicationTowersLayer;
