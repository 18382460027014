import { noop } from "lodash";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import useMapContext from "../Map/useMapContext";
import {
  getFireMapperGISUrls,
  type FireMapperLayerProps,
  fireMapperGISLayers,
} from "./utils";

const FireMapperRasterLayer = (props: FireMapperLayerProps) => {
  const layerId = `fireMapper${props.layer}${props.layerType}`;
  const map = useMapContext();
  const accessToken = useAuthAccessToken();
  const { tileUrl } = getFireMapperGISUrls(props.layer, props.layerType);

  useEffect(() => {
    if (!accessToken) return noop;

    const controller = new AbortController();
    controller.signal.addEventListener("abort", () => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }

      if (map.getSource(layerId)) {
        map.removeSource(layerId);
      }
    });

    if (!map.getSource(layerId)) {
      const query = buildMapboxBBoxQuery({
        dpi: "96",
        format: "png32",
        layers: `show:${fireMapperGISLayers[props.layerType][props.layer]}`,
        transparent: "true",
        bboxSR: "3857",
        imageSR: "3857",
        size: "512,512",
        f: "image",
      });

      map.addSource(layerId, {
        type: "raster",
        tileSize: 512,
        tiles: [`${tileUrl}?${query}`],
      });
    }

    if (!map.getLayer(layerId)) {
      map.addLayer(
        { id: layerId, type: "raster", source: layerId },
        MapLevel.SYMBOLS,
      );
    }

    return () => controller.abort();
  }, [accessToken, layerId, map, props.layer, props.layerType, tileUrl]);

  return null;
};

export default FireMapperRasterLayer;
