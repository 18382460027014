import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";

const BURNT_AREA_POLYGONS_QUERY = buildMapboxBBoxQuery({
  dpi: "96",
  format: "png32",
  layers: "show:0,1,2,3",
  transparent: "true",
  bboxSR: "3857",
  imageSR: "3857",
  size: "512,512",
  f: "image",
});

const BURNT_AREA_POLYGONS_ID = "burntAreaPolygons";
const BURNT_AREA_POLYGONS_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Operations/IconFireFeatures/MapServer/export?${BURNT_AREA_POLYGONS_QUERY}`;
const FIRE_FEATURES_META_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Operations/IconFireFeatures/MapServer?f=json`;

/**
 * @see https://gis.stackexchange.com/questions/111584/what-is-the-significance-of-591657550-5-as-it-relates-to-google-maps-scaling-fac
 */
const SCALE_RATIO_CONSTANT = 591657550.5;

const arcgisMinScaleToMapboxMinzoom = (minScale: number) => {
  const zoom = Math.log2(SCALE_RATIO_CONSTANT / minScale) + 1;
  return Math.ceil(zoom);
};

interface FireFeaturesLayerMeta {
  minScale: number;
}

const getFireFeaturesMinzoom = async () => {
  const response = await axios.get<FireFeaturesLayerMeta>(
    FIRE_FEATURES_META_URL,
  );

  return arcgisMinScaleToMapboxMinzoom(response.data.minScale);
};

const BurntAreaPolygonsLayer = () => {
  const map = useMapContext();

  const { data: minzoom, isPending } = useQuery({
    queryKey: ["icon-fire-features-minscale"],
    queryFn: getFireFeaturesMinzoom,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isPending) {
      return;
    }

    map.addSource(BURNT_AREA_POLYGONS_ID, {
      type: "raster",
      tileSize: 512,
      minzoom,
      tiles: [BURNT_AREA_POLYGONS_URL],
    });

    map.addLayer(
      {
        id: BURNT_AREA_POLYGONS_ID,
        type: "raster",
        source: BURNT_AREA_POLYGONS_ID,
      },
      MapLevel.SYMBOLS,
    );

    return () => {
      map.removeLayer(BURNT_AREA_POLYGONS_ID);
      map.removeSource(BURNT_AREA_POLYGONS_ID);
    };
  }, [isPending, map, minzoom]);

  return null;
};

export default BurntAreaPolygonsLayer;
