import type { BurntAreaCurrentFireSeasonInteractionState } from "../../map/BurntAreaCurrentFireSeason/interactions";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import BurntAreaCurrentFireSeasonPopupDetail from "./BurntAreaCurrentFireSeasonPopupDetail";

export interface BurntAreaCurrentFireSeasonPopupProps {
  "data-testid"?: string;
  onClose: () => void;
  state: BurntAreaCurrentFireSeasonInteractionState;
}

const BurntAreaCurrentFireSeasonPopup = ({
  "data-testid": dataTestId,
  onClose,
  state: { id: featureId, isActive, properties },
}: BurntAreaCurrentFireSeasonPopupProps) => {
  return (
    <SpatialPopup
      isOpen={isActive}
      lngLat={properties?.lngLat}
      onClose={onClose}
      popupConfig={{
        id: featureId,
        offset: 16,
        size: "lg",
        type: "click",
      }}
    >
      {properties && (
        <MapPopupView data-testid={dataTestId} onClose={onClose}>
          <BurntAreaCurrentFireSeasonPopupDetail properties={properties} />
        </MapPopupView>
      )}
    </SpatialPopup>
  );
};

export default BurntAreaCurrentFireSeasonPopup;
