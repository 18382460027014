import { Alert } from "@app/design-system";
import { useGetIncidentsId } from "../../../../.rest-hooks/incidents";
import useQueryAsyncValue from "../../../hooks/useQueryAsyncValue";
import type { IncidentCtaProps } from "../../drawers/IncidentsDrawerCell/IncidentCard";
import type { IncidentPopupRenderProps } from "../../map/IncidentPointCollection/IncidentPointCollectionLayer";
import type { PreferredPredictionAttribute } from "../../ui/PreferredPredictionValidIndicator/PreferredPredictionValidIndicator";
import AsyncView from "../../util/AsyncView/AsyncView";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import IncidentPopupView from "./IncidentPopupView";
import SkeletonIncidentPopup from "./SkeletonIncidentPopup";

interface IncidentPopupProps extends IncidentPopupRenderProps {
  cta: React.ComponentType<IncidentCtaProps>;
  isCopView?: boolean;
  predictionAttribute: PreferredPredictionAttribute;
}

const IncidentPopup = ({
  cta,
  isCopView = false,
  onClose,
  predictionAttribute,
  state,
}: IncidentPopupProps) => {
  const { isActive, properties } = state;
  const incidentId = properties?.incidentId;

  const incidentQuery = useGetIncidentsId(incidentId!, {
    query: {
      enabled: !!incidentId,
      select: (data) => data.data.data,
    },
  });

  const incidentResult = useQueryAsyncValue({ query: incidentQuery });

  return (
    <SpatialPopup
      isOpen={isActive}
      lngLat={properties?.lngLat}
      popupConfig={{
        id: state.id,
        offset: 32,
        size: "lg",
        type: "click",
      }}
      onClose={onClose}
    >
      <AsyncView
        {...incidentResult}
        errorState={
          <MapPopupView onClose={onClose}>
            <Alert title="Unable to load incident" variant="error" />
          </MapPopupView>
        }
        loadingState={
          <SkeletonIncidentPopup isCopView={isCopView} onClose={onClose} />
        }
      >
        {(incident) => (
          <IncidentPopupView
            cta={cta}
            incident={incident}
            isCopView={isCopView}
            predictionAttribute={predictionAttribute}
            onClose={onClose}
          />
        )}
      </AsyncView>
    </SpatialPopup>
  );
};

export default IncidentPopup;
