import PreviewSpatialPopup from "../../popup/PreviewSpatialPopup/PreviewSpatialPopup";
import type { IncidentPointFeatureCollection } from "../IncidentPoint/utils";
import useInteractionFeatureState from "../MapInteractions/useInteractionFeatureState";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import {
  INCIDENT_POINT_COLLECTION_LAYER_ID,
  INCIDENT_POINT_COLLECTION_SOURCE_ID,
} from "./constants";
import {
  type IncidentInteractionState,
  getPropertiesFromFeature,
} from "./interactions";
import useIncidentPointCollectionMapData from "./useIncidentPointCollectionMapData";
import useIncidentPointCollectionMapLayers from "./useIncidentPointCollectionMapLayers";

export interface IncidentPopupRenderProps {
  onClose: () => void;
  state: IncidentInteractionState;
}

interface IncidentPointCollectionProps {
  geojson: IncidentPointFeatureCollection;
  renderPopup: (props: IncidentPopupRenderProps) => React.ReactNode;
}

const IncidentPointCollectionLayer = ({
  geojson,
  renderPopup,
}: IncidentPointCollectionProps) => {
  useIncidentPointCollectionMapLayers();
  useIncidentPointCollectionMapData({ geojson });

  const interactionState = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: INCIDENT_POINT_COLLECTION_LAYER_ID,
  });

  const {
    clickedState,
    deactivateClickState,
    deactivateHoverState,
    hoveredState,
  } = interactionState;

  useInteractionFeatureState({
    getFeatureId: (properties) => properties.incidentId,
    clickedState,
    hoveredState,
    sourceId: INCIDENT_POINT_COLLECTION_SOURCE_ID,
  });

  return (
    <>
      <PreviewSpatialPopup
        offset="lg"
        onClose={deactivateHoverState}
        state={hoveredState}
      >
        {hoveredState.properties?.name || "—"}
      </PreviewSpatialPopup>
      {renderPopup({
        state: clickedState,
        onClose: deactivateClickState,
      })}
    </>
  );
};

export default IncidentPointCollectionLayer;
