import getConfig from "next/config";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import useMapContext from "../Map/useMapContext";

const { publicRuntimeConfig } = getConfig();

const SATELLITE_ID = "satellite";

const SATELLITE_URLS = [
  "https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png8?apiKey=",
  "https://2.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png8?apiKey=",
  "https://3.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png8?apiKey=",
  "https://4.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png8?apiKey=",
];

const { NEXT_HERE_API_KEY } = publicRuntimeConfig;

const SatelliteLayer = () => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(SATELLITE_ID, {
      type: "raster",
      tiles: SATELLITE_URLS.map((url) => `${url}${NEXT_HERE_API_KEY}`),
      tileSize: 256,
    });

    map.addLayer(
      {
        id: SATELLITE_ID,
        type: "raster",
        source: SATELLITE_ID,
      },
      MapLevel.BASE,
    );

    return () => {
      map.removeLayer(SATELLITE_ID);
      map.removeSource(SATELLITE_ID);
    };
  }, [map]);

  return null;
};

export default SatelliteLayer;
