import { FieldGrid, Text } from "@app/design-system";
import styled from "styled-components";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import type { TelephoneExchangeInteractionProperties } from "../../map/TelephoneExchange/interactions";

const StyledBody = styled.div`
  display: grid;
  gap: 0.5rem;
`;

export interface TelephoneExchangePopupDetailProps {
  properties: TelephoneExchangeInteractionProperties;
}

const TelephoneExchangePopupDetail = ({
  properties,
}: TelephoneExchangePopupDetailProps) => {
  return (
    <StyledBody>
      <Text size="subtitleMd">{properties.data.exchgename}</Text>
      <FieldGrid>
        <FieldGrid.Item label="Source:">
          {properties.data.srcagency || <FallbackElement />}
        </FieldGrid.Item>
        <FieldGrid.Item label="Locality:">
          {properties.data.locality || <FallbackElement />}
        </FieldGrid.Item>
        <FieldGrid.Item label="Address:">
          {properties.data.staddrtag || <FallbackElement />}
        </FieldGrid.Item>
      </FieldGrid>
    </StyledBody>
  );
};

export default TelephoneExchangePopupDetail;
