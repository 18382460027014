import {
  AlertModal,
  DEFAULT_ERROR_MESSAGE,
  Fire,
  FireFilled,
  Tooltip,
} from "@app/design-system";
import { useEffect, useMemo } from "react";
import { useBoolean } from "usehooks-ts";
import { incidentIcons } from "../../../config/layers/layers";
import { useHasPrivilege } from "../../../hooks/useHasPrivilege";
import DrawerOverlay from "../../drawers/DrawerOverlay";
import IncidentsDrawerCell from "../../drawers/IncidentsDrawerCell/IncidentsDrawerCell";
import {
  incidentCategoryDefault,
  incidentStatusDefault,
} from "../../drawers/IncidentsDrawerCell/constants";
import { SwitchPredictionControlDrawerCell } from "../../drawers/PredictionControlDrawerCell/PredictionControlDrawerCell";
import MapLayout from "../../layout/MapLayout/MapLayout";
import ActivePredictions from "../../map/ActivePredictions/ActivePredictions";
import CommonMapLayers from "../../map/CommonMapLayers/CommonMapLayers";
import IncidentPointCollection from "../../map/IncidentPointCollection/IncidentPointCollection";
import MapView from "../../map/Map/MapView";
import useUnsafeMapContext from "../../map/Map/useUnsafeMapContext";
import MapControlsAndBaseLayers from "../../map/MapControlsAndBaseLayers/MapControlsAndBaseLayers";
import SocialMediaClustersStateMap from "../../map/SocialMediaClustersStateMap/SocialMediaClustersStateMap";
import useIncidentPredictionsByBbox from "../../map/hooks/useIncidentPredictionsByBbox/useIncidentPredictionsByBbox";
import useIncidentPredictionsEndorsed from "../../map/hooks/useIncidentPredictionsEndorsed/useIncidentPredictionsEndorsed";
import useMapZoomLevel from "../../map/hooks/useMapZoomLevel/useMapZoomLevel";
import IncidentPopup from "../../popup/IncidentPopup/IncidentPopup";
import IncidentsSubheader from "../../ui/IncidentsSubheader/IncidentsSubheader";
import { coordinateConversionMapRailItem } from "../../ui/MapRail/CoordinateConversionDrawer/CoordinateConversionDrawer";
import IncidentsDrawer from "../../ui/MapRail/IncidentsDrawer/IncidentsDrawer";
import { layersMapRailItem } from "../../ui/MapRail/LayersDrawer/LayersDrawer";
import { linksMapRailItem } from "../../ui/MapRail/LinksDrawer/LinksDrawer";
import MapRailDrawerList from "../../ui/MapRail/MapRailDrawerList";
import MapRailMenu from "../../ui/MapRail/MapRailMenu";
import MapRailOverlay from "../../ui/MapRail/MapRailOverlay";
import MapRailPill from "../../ui/MapRail/MapRailPill";
import { MapRailProvider } from "../../ui/MapRail/MapRailProvider";
import { measurementToolMapRailItem } from "../../ui/MapRail/MeasurementToolDrawer/MeasurementToolDrawer";
import Minimap from "../../ui/MapRail/Minimap/Minimap";
import PredictionControlDrawer from "../../ui/MapRail/PredictionControlDrawer/PredictionControlDrawer";
import { searchMapRailItem } from "../../ui/MapRail/SearchDrawer/SearchDrawer";
import SwitchMapRailPill from "../../ui/MapRail/SwitchMapRailPill";
import {
  createMapRailItem,
  useMapRail,
  type MapRailItem,
} from "../../ui/MapRail/useMapRail";
import {
  useActivatePredictions,
  useAddPredictions,
} from "../../ui/PredictionsProvider/PredictionsProvider";
import ViewIncidentButton from "../../ui/ViewIncidentButton/ViewIncidentButton";
import StateViewBottomTabs from "../../ui/VisualiserBottomTabs/StateViewBottomTabs";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import { useDeveloperOptions } from "../../util/DeveloperOptionsProvider/DeveloperOptionsProvider";
import { useIncidentsScope } from "../IncidentsScopeProvider/IncidentsScopeProvider";

export const MAX_INCIDENTS = 5000;

const PREDICTIONS_ENABLED_ZOOM_LEVEL = 8;

interface StateViewProps {
  isCopView?: boolean;
}

const StateView = ({ isCopView = false }: StateViewProps) => {
  const { options } = useDeveloperOptions();

  const {
    setFalse: deactivatePredictions,
    setValue: setPredictionsActive,
    value: isPredictionsActive,
  } = useBoolean();
  const {
    value: isPredictionsAlertModalOpen,
    setTrue: openPredictionsAlertModal,
    setFalse: closePredictionsAlertModal,
  } = useBoolean();

  const { map } = useUnsafeMapContext();

  const zoomLevel = useMapZoomLevel();

  const isPredictionsEnabledForZoomLevel =
    !!zoomLevel && zoomLevel >= PREDICTIONS_ENABLED_ZOOM_LEVEL;

  const { hasPrivilege: hasEndorsedPredictionPrivilege } = useHasPrivilege({
    requestedPermissions: ["prediction:endorsed:read"],
  });
  const { hasPrivilege: hasPredictionOpenPrivilege } = useHasPrivilege({
    requestedPermissions: ["prediction:read"],
  });

  const hasPredictionPrivilege = isCopView
    ? hasEndorsedPredictionPrivilege
    : hasPredictionOpenPrivilege;

  useEffect(() => {
    if (map) {
      map.setMinZoom(isPredictionsActive ? 8 : null);
    }
  }, [isPredictionsActive, map]);

  const { data: predictionsAll } = useIncidentPredictionsByBbox({
    enabled:
      !isCopView && isPredictionsEnabledForZoomLevel && isPredictionsActive,
    onError: () => {
      openPredictionsAlertModal();
    },
  });

  const { data: predictionsEndorsed } = useIncidentPredictionsEndorsed({
    enabled:
      isCopView && isPredictionsEnabledForZoomLevel && isPredictionsActive,
    onError: () => {
      openPredictionsAlertModal();
    },
  });

  const predictions = isCopView ? predictionsEndorsed : predictionsAll;

  const { scope, onScopeChange } = useIncidentsScope();

  useAddPredictions({
    predictions,
  });

  useActivatePredictions({
    isImpactsCountActive: true,
    predictions,
  });

  const items = useMemo<Map<string, MapRailItem>>(() => {
    return new Map([
      layersMapRailItem,
      createMapRailItem({
        component: IncidentsDrawer,
        icon: Fire,
        iconOn: FireFilled,
        label: "Incidents",
        id: "incidents",
        props: {
          cta: ViewIncidentButton,
          predictionAttribute: "validTo",
          isCopView,
        },
      }),
      searchMapRailItem,
      coordinateConversionMapRailItem,
      measurementToolMapRailItem,
      linksMapRailItem,
    ]);
  }, [isCopView]);

  const mapRail = useMapRail({ items });

  const { activateLayer, isLayerActive } = useActiveLayersContext();

  return (
    <>
      <MapLayout
        drawerOverlay={
          options.isMapRailEnabled ? (
            <MapRailProvider {...mapRail}>
              <MapRailOverlay
                menu={<MapRailMenu />}
                minimap={<Minimap />}
                pills={
                  <>
                    {hasPredictionPrivilege && (
                      <Tooltip
                        delay
                        message={
                          !isPredictionsEnabledForZoomLevel &&
                          "Zoom in to enable predictions"
                        }
                        placement="bottom"
                      >
                        <div>
                          <SwitchMapRailPill
                            isDisabled={!isPredictionsEnabledForZoomLevel}
                            isSelected={isPredictionsActive}
                            label={
                              isCopView ? "Endorsed predictions" : "Predictions"
                            }
                            onChange={setPredictionsActive}
                          />
                        </div>
                      </Tooltip>
                    )}
                    {(scope.status !== incidentStatusDefault ||
                      scope.category !== incidentCategoryDefault(isCopView)) &&
                      isLayerActive(incidentIcons.id) && (
                        <MapRailPill
                          action="Undo"
                          onClick={() =>
                            onScopeChange({
                              ...scope,
                              status: incidentStatusDefault,
                              category: incidentCategoryDefault(isCopView),
                            })
                          }
                          status="warning"
                        >
                          Incident filters applied
                        </MapRailPill>
                      )}
                    {!isLayerActive(incidentIcons.id) && (
                      <MapRailPill
                        action="Undo"
                        onClick={() =>
                          activateLayer({
                            id: incidentIcons.id,
                            source: "option-layer",
                          })
                        }
                        status="warning"
                      >
                        Incidents hidden
                      </MapRailPill>
                    )}
                  </>
                }
                slider={
                  isPredictionsActive && (
                    <PredictionControlDrawer
                      disabled={
                        !isPredictionsEnabledForZoomLevel ||
                        !hasPredictionPrivilege
                      }
                    />
                  )
                }
              >
                <MapRailDrawerList />
              </MapRailOverlay>
            </MapRailProvider>
          ) : (
            <DrawerOverlay>
              <IncidentsDrawerCell
                cta={ViewIncidentButton}
                predictionAttribute="validTo"
                isCopView={isCopView}
              />
              {!isCopView && (
                <SwitchPredictionControlDrawerCell
                  disabled={!isPredictionsEnabledForZoomLevel}
                  isSelected={isPredictionsActive}
                  onChange={setPredictionsActive}
                />
              )}
            </DrawerOverlay>
          )
        }
        bottomTabs={
          <StateViewBottomTabs
            cta={ViewIncidentButton}
            predictionAttribute="validTo"
            isCopView={isCopView}
          />
        }
        subheader={<IncidentsSubheader variant="immersive" />}
      >
        <MapView layout={options.isMapRailEnabled ? "mapRail" : "default"}>
          <IncidentPointCollection
            renderPopup={(props) => (
              <IncidentPopup
                {...props}
                cta={ViewIncidentButton}
                isCopView={isCopView}
                predictionAttribute="validTo"
              />
            )}
          />
          <MapControlsAndBaseLayers
            isMapRailEnabled={options.isMapRailEnabled}
            isPredictionsActive={isPredictionsActive}
          />
          {isPredictionsActive && isPredictionsEnabledForZoomLevel && (
            <ActivePredictions />
          )}
          <CommonMapLayers isCopView={isCopView} />
          <SocialMediaClustersStateMap isCopView={isCopView} />
        </MapView>
      </MapLayout>
      <AlertModal
        title="Unable to load predictions"
        isOpen={isPredictionsAlertModalOpen}
        onDismiss={() => {
          closePredictionsAlertModal();
          deactivatePredictions();
        }}
      >
        {`There was an error loading the incident predictions. ${DEFAULT_ERROR_MESSAGE}`}
      </AlertModal>
    </>
  );
};

export default StateView;
