import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import useMapContext from "../Map/useMapContext";
import { InteractionStateType } from "../MapInteractions/types";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { BURNT_AREA_HAZARD_REDUCTION_QUERY_URL } from "./constants";

interface UseBurntAreaHazardReductionMapLayerParams {
  layerId: string;
  opacity?: number;
  queryOptions: QueryOptions;
}

const useBurntAreaHazardReductionMapLayer = ({
  layerId,
  opacity = 1,
  queryOptions,
}: UseBurntAreaHazardReductionMapLayerParams) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(
    BURNT_AREA_HAZARD_REDUCTION_QUERY_URL,
    queryOptions,
  );
  const textLayerId = `${layerId}-text`;

  useEffect(() => {
    map.addSource(layerId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        type: "line",
        id: layerId,
        source: layerId,
        paint: {
          "line-color": [
            "case",
            [
              "any",
              [
                "boolean",
                ["feature-state", InteractionStateType.HOVERED],
                false,
              ],
              [
                "boolean",
                ["feature-state", InteractionStateType.CLICKED],
                false,
              ],
            ],
            "#03ffff",
            "#ffaa01",
          ],
          "line-width": 4,
        },
      },
      MapLevel.LINES,
    );

    map.addLayer(
      {
        id: textLayerId,
        type: "symbol",
        source: layerId,
        layout: {
          "text-field": ["get", "METHOD"],
          "text-size": 12,
          "symbol-placement": "point",
        },
        minzoom: 12,
      },
      MapLevel.SYMBOLS,
    );

    return () => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
      if (map.getLayer(textLayerId)) {
        map.removeLayer(textLayerId);
      }
      if (map.getSource(layerId)) {
        map.removeSource(layerId);
      }
    };
  }, [layerId, map, textLayerId]);

  useEffect(() => {
    map.setPaintProperty(layerId, "line-opacity", opacity);
  }, [layerId, map, opacity]);

  useEffect(() => {
    const source = map.getSource(layerId);

    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, layerId, map]);
};

export default useBurntAreaHazardReductionMapLayer;
