import styled from "styled-components";
import type { ACTESAResourcesInteractionState } from "../../map/ACTESAResources/interactions";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import ACTESAResourcesPopupDetail from "./ACTESAResourcesPopupDetail";

const StyledHeader = styled.div`
  ${(p) => p.theme.typography.variants.eyebrowDefault};
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  margin: auto 0;
`;

export interface ACTESAResourcesPopupProps {
  "data-testid"?: string;
  onClose: () => void;
  state: ACTESAResourcesInteractionState;
}

const ACTESAResourcesPopup = ({
  "data-testid": dataTestId,
  onClose,
  state: { id: featureId, isActive, properties },
}: ACTESAResourcesPopupProps) => {
  return (
    <SpatialPopup
      isOpen={isActive}
      lngLat={properties?.lngLat}
      onClose={onClose}
      popupConfig={{
        id: featureId,
        offset: 16,
        size: "lg",
        type: "click",
      }}
    >
      {properties && (
        <MapPopupView
          data-testid={dataTestId}
          header={
            <StyledHeader>
              {properties.data.VSC}-{properties.data.VDS}
            </StyledHeader>
          }
          onClose={onClose}
        >
          <ACTESAResourcesPopupDetail properties={properties} />
        </MapPopupView>
      )}
    </SpatialPopup>
  );
};

export default ACTESAResourcesPopup;
