import type { Feature } from "geojson";
import type { LngLatLike } from "mapbox-gl";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isTweetFeature } from "./types";

export interface TweetInteractionProperties
  extends FeatureInteractionProperties {
  tweetId: string;
}

export type TweetInteractionState =
  FeatureInteractionState<TweetInteractionProperties>;

export const getPropertiesFromFeature = (
  feature: Feature,
): TweetInteractionProperties | null => {
  if (!isTweetFeature(feature)) {
    return null;
  }

  const lngLat = feature.geometry.coordinates as LngLatLike;
  const { tweetId } = feature.properties;

  return { lngLat, tweetId };
};
