import type { Feature } from "geojson";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import {
  type MapboxMouseEvent,
  isMultiPolygonFeature,
  isPolygonFeature,
} from "../types";
import type { BurntAreaCurrentFireSeasonFeature } from "./gis";

export interface BurntAreaCurrentFireSeasonInteractionProperties
  extends FeatureInteractionProperties {
  data: BurntAreaCurrentFireSeasonData;
  objectId: string;
}

export interface BurntAreaCurrentFireSeasonData {
  burntArea?: number;
  date?: number;
  incidentName?: string;
}

export type BurntAreaCurrentFireSeasonInteractionState =
  FeatureInteractionState<BurntAreaCurrentFireSeasonInteractionProperties>;

export const getPropertiesFromFeature = (
  feature: Feature,
  event: MapboxMouseEvent | maplibregl.MapLayerMouseEvent,
): BurntAreaCurrentFireSeasonInteractionProperties | null => {
  if (!isMultiPolygonFeature(feature) && !isPolygonFeature(feature)) {
    return null;
  }

  const { lngLat } = event;
  const burntAreaFeature = feature as BurntAreaCurrentFireSeasonFeature;

  return {
    data: {
      burntArea: burntAreaFeature.properties.BurntArea ?? undefined,
      date: burntAreaFeature.properties.CAPTUREDDATETIME ?? undefined,
      incidentName: burntAreaFeature.properties.IncidentName ?? undefined,
    },
    featureId: burntAreaFeature.properties.OBJECTID,
    objectId: burntAreaFeature.properties.OBJECTID,
    lngLat,
  };
};
