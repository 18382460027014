import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import useMapContext from "../Map/useMapContext";

const DEA_HOTSPOTS_QUERY = buildMapboxBBoxQuery({
  SERVICE: "WMS",
  REQUEST: "GetMap",
  FORMAT: "image/png",
  TRANSPARENT: "TRUE",
  STYLES: "",
  VERSION: "1.3.0",
  LAYERS: "hotspots_three_days",
  WIDTH: "512",
  HEIGHT: "512",
  CRS: "EPSG:3857",
});

const DEA_HOTSPOTS_ID = "deaHotspots";

interface DEAHotspotsLayerProps {
  opacity?: number;
}

const DEAHotspotsLayer = ({ opacity = 1 }: DEAHotspotsLayerProps) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(DEA_HOTSPOTS_ID, {
      type: "raster",
      tileSize: 512,
      tiles: [
        `https://hotspots.dea.ga.gov.au/geoserver/wms?${DEA_HOTSPOTS_QUERY}`,
      ],
    });

    map.addLayer(
      {
        id: DEA_HOTSPOTS_ID,
        type: "raster",
        source: DEA_HOTSPOTS_ID,
      },
      MapLevel.SYMBOLS,
    );

    return () => {
      map.removeLayer(DEA_HOTSPOTS_ID);
      map.removeSource(DEA_HOTSPOTS_ID);
    };
  }, [map]);

  useEffect(() => {
    map?.setPaintProperty(DEA_HOTSPOTS_ID, "raster-opacity", opacity);
  }, [map, opacity]);

  return null;
};

export default DEAHotspotsLayer;
