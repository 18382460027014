import AircraftPositionsPopup from "../../popup/AircraftPositionsPopup/AircraftPositionsPopup";
import useInteractionFeatureState from "../MapInteractions/useInteractionFeatureState";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import {
  AIRCRAFT_POSITIONS_LAYER_ID,
  AIRCRAFT_POSITIONS_SOURCE_ID,
} from "./constants";
import { getPropertiesFromFeature } from "./interactions";
import useAircraftPositionsMapData from "./useAircraftPositionsMapData";
import useAircraftPositionsMapLayer from "./useAircraftPositionsMapLayer";

interface AircraftPositionsLayerProps {
  isCopView: boolean;
}

const AircraftPositionsLayer = ({ isCopView }: AircraftPositionsLayerProps) => {
  useAircraftPositionsMapLayer();
  useAircraftPositionsMapData();

  const interactionState = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: AIRCRAFT_POSITIONS_LAYER_ID,
  });

  const {
    clickedState,
    deactivateClickState,
    deactivateHoverState,
    hoveredState,
  } = interactionState;

  useInteractionFeatureState({
    getFeatureId: (properties) => properties.id,
    clickedState,
    hoveredState,
    sourceId: AIRCRAFT_POSITIONS_SOURCE_ID,
  });

  return (
    <>
      <AircraftPositionsPopup
        isCopView={isCopView}
        onClose={deactivateHoverState}
        state={hoveredState}
        type="hover"
      />
      <AircraftPositionsPopup
        isCopView={isCopView}
        onClose={deactivateClickState}
        state={clickedState}
        type="click"
      />
    </>
  );
};

export default AircraftPositionsLayer;
