import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import { NSW_BORDER } from "./nswBorder";

const TOPO_ID = "topo";
const NSW_FILL = "fillNSW";

const TopographicLayer = () => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(TOPO_ID, {
      type: "raster",
      tileSize: 256,
      tiles: [
        `${getMapServerProxyBasepath()}/arcgis/rest/services/Cache/NSW_TopoRasters_WM/MapServer/tile/{z}/{y}/{x}`,
      ],
    });

    map.addLayer(
      {
        id: TOPO_ID,
        type: "raster",
        source: TOPO_ID,
      },
      MapLevel.BASE,
    );

    map.addLayer(
      {
        type: "fill",
        id: NSW_FILL,
        source: {
          type: "geojson",
          data: NSW_BORDER,
        } satisfies maplibregl.GeoJSONSourceSpecification,
        paint: {
          "fill-color": "#fff",
        },
      },
      TOPO_ID,
    );

    return () => {
      map.removeLayer(TOPO_ID);
      map.removeSource(TOPO_ID);
      map.removeLayer(NSW_FILL);
      map.removeSource(NSW_FILL);
    };
  }, [map]);

  return null;
};

export default TopographicLayer;
