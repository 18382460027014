import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";

const EMERGENCY_ALERTS_QUERY = buildMapboxBBoxQuery({
  dpi: "96",
  format: "png32",
  layers: "show:3",
  transparent: "true",
  bboxSR: "3857",
  imageSR: "3857",
  size: "512,512",
  f: "image",
  layerDefs: '{"3":"FIREPOLYGONTYPE = 7"}',
});

const EMERGENCY_ALERTS_ID = "emergencyAlerts";
const EMERGENCY_ALERTS_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Operations/IconFireFeatures/MapServer/export?${EMERGENCY_ALERTS_QUERY}`;

interface EmergencyAlertsLayerProps {
  opacity?: number;
}

const EmergencyAlertsLayer = ({ opacity = 1 }: EmergencyAlertsLayerProps) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(EMERGENCY_ALERTS_ID, {
      type: "raster",
      tileSize: 512,
      tiles: [EMERGENCY_ALERTS_URL],
    });

    map.addLayer(
      {
        id: EMERGENCY_ALERTS_ID,
        type: "raster",
        source: EMERGENCY_ALERTS_ID,
      },
      MapLevel.SYMBOLS,
    );

    return () => {
      map.removeLayer(EMERGENCY_ALERTS_ID);
      map.removeSource(EMERGENCY_ALERTS_ID);
    };
  }, [map]);

  useEffect(() => {
    map.setPaintProperty(EMERGENCY_ALERTS_ID, "raster-opacity", opacity);
  }, [map, opacity]);

  return null;
};

export default EmergencyAlertsLayer;
