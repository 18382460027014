import type React from "react";
import { useEffect } from "react";
import useMapContext from "../Map/useMapContext";

const Sky: React.FC = () => {
  const map = useMapContext("mapbox");

  useEffect(() => {
    map.addLayer({
      id: "sky",
      type: "sky",
      paint: {
        // set up the sky layer to use a color gradient
        "sky-type": "gradient",
        // the sky will be lightest in the center and get darker moving radially outward
        // this simulates the look of the sun just below the horizon
        "sky-gradient": [
          "interpolate",
          ["linear"],
          ["sky-radial-progress"],
          0.8,
          "rgba(135, 206, 235, 1.0)",
          1,
          "rgba(0,0,0,0.1)",
        ],
        "sky-gradient-center": [0, 0],
        "sky-gradient-radius": 90,
        "sky-opacity": [
          "interpolate",
          ["exponential", 0.1],
          ["zoom"],
          5,
          0,
          22,
          1,
        ],
      },
    });

    return () => {
      map.removeLayer("sky");
    };
  }, [map]);

  return null;
};

export default Sky;
