import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import BurntAreaCurrentFireSeasonPopup from "../../popup/BurntAreaCurrentFireSeasonPopup/BurntAreaCurrentFireSeasonPopup";
import useMapContext from "../Map/useMapContext";
import { InteractionStateType } from "../MapInteractions/types";
import useInteractionFeatureState from "../MapInteractions/useInteractionFeatureState";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";
import { BURNT_AREA_CURRENT_FIRE_SEASON_FIELD_NAMES } from "./gis";
import { getPropertiesFromFeature } from "./interactions";

const BURNT_AREA_CURRENT_FIRE_SEASON_ID = "burntAreaCurrentFireSeason";
const BURNT_AREA_CURRENT_FIRE_SEASON_FILL_ID = "burntAreaCurrentFireSeasonFill";
const BURNT_AREA_CURRENT_FIRE_SEASON_OUTLINE_ID =
  "burntAreaCurrentFireSeasonOutline";

const BURNT_AREA_CURRENT_FIRE_SEASON_QUERY_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/WildfireHistory/MapServer/3/query`;
const BURNT_AREA_CURRENT_FIRE_SEASON_QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    outFields: BURNT_AREA_CURRENT_FIRE_SEASON_FIELD_NAMES.join(","),
  },
};

const IMG_PREVIOUSLY_BURNT_AREA_PATTERN_ID =
  "firemap-fire-previously-burnt-area";
const IMG_PREVIOUSLY_BURNT_AREA_PATTERN_SRC =
  "/patterns/firemap-fire-previously-burnt-area.png";

const MAP_STYLING = {
  opacity: {
    default: 0.8,
    active: 1.0,
  },
  outlineWidth: {
    default: 1,
    active: 2,
  },
} as const;

const addBurntAreaMapLayers = (map: ReturnType<typeof useMapContext>) => {
  map.addLayer(
    {
      id: BURNT_AREA_CURRENT_FIRE_SEASON_FILL_ID,
      type: "fill",
      source: BURNT_AREA_CURRENT_FIRE_SEASON_ID,
      layout: {
        "fill-sort-key": ["get", "BurntArea"],
      },
      paint: {
        "fill-pattern": IMG_PREVIOUSLY_BURNT_AREA_PATTERN_ID,
        "fill-opacity": [
          "case",
          [
            "any",
            ["boolean", ["feature-state", InteractionStateType.HOVERED], false],
            ["boolean", ["feature-state", InteractionStateType.CLICKED], false],
          ],
          MAP_STYLING.opacity.active,
          MAP_STYLING.opacity.default,
        ],
      },
    },
    MapLevel.BACKGROUND,
  );

  map.addLayer(
    {
      id: BURNT_AREA_CURRENT_FIRE_SEASON_OUTLINE_ID,
      type: "line",
      source: BURNT_AREA_CURRENT_FIRE_SEASON_ID,
      layout: {
        "line-sort-key": ["get", "BurntArea"],
      },
      paint: {
        "line-color": "#000",
        "line-opacity": [
          "case",
          [
            "any",
            ["boolean", ["feature-state", InteractionStateType.HOVERED], false],
            ["boolean", ["feature-state", InteractionStateType.CLICKED], false],
          ],
          MAP_STYLING.opacity.active,
          MAP_STYLING.opacity.default,
        ],
        "line-width": [
          "case",
          [
            "any",
            ["boolean", ["feature-state", InteractionStateType.HOVERED], false],
            ["boolean", ["feature-state", InteractionStateType.CLICKED], false],
          ],
          MAP_STYLING.outlineWidth.active,
          MAP_STYLING.outlineWidth.default,
        ],
      },
    },
    MapLevel.BACKGROUND,
  );
};

const BurntAreaCurrentFireSeasonLayer = () => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(
    BURNT_AREA_CURRENT_FIRE_SEASON_QUERY_URL,
    BURNT_AREA_CURRENT_FIRE_SEASON_QUERY_OPTIONS,
  );

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(BURNT_AREA_CURRENT_FIRE_SEASON_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    loadImage({
      imageId: IMG_PREVIOUSLY_BURNT_AREA_PATTERN_ID,
      map,
      src: IMG_PREVIOUSLY_BURNT_AREA_PATTERN_SRC,
      signal: controller.signal,
    }).then(() => {
      addBurntAreaMapLayers(map);
    }, catchAbortError);

    return () => {
      controller.abort();

      if (map.getLayer(BURNT_AREA_CURRENT_FIRE_SEASON_FILL_ID)) {
        map.removeLayer(BURNT_AREA_CURRENT_FIRE_SEASON_FILL_ID);
      }
      if (map.getLayer(BURNT_AREA_CURRENT_FIRE_SEASON_OUTLINE_ID)) {
        map.removeLayer(BURNT_AREA_CURRENT_FIRE_SEASON_OUTLINE_ID);
      }
      if (map.getSource(BURNT_AREA_CURRENT_FIRE_SEASON_ID)) {
        map.removeSource(BURNT_AREA_CURRENT_FIRE_SEASON_ID);
      }
    };
  }, [map]);

  useEffect(() => {
    const source = map.getSource(BURNT_AREA_CURRENT_FIRE_SEASON_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  const { deactivateClickState, clickedState, hoveredState } =
    useLayerInteractions({
      getPropertiesFromFeature,
      layerId: BURNT_AREA_CURRENT_FIRE_SEASON_FILL_ID,
    });

  useInteractionFeatureState({
    clickedState,
    getFeatureId: (p) => p.objectId,
    hoveredState,
    sourceId: BURNT_AREA_CURRENT_FIRE_SEASON_ID,
  });

  return (
    <BurntAreaCurrentFireSeasonPopup
      onClose={deactivateClickState}
      state={clickedState}
    />
  );
};

export default BurntAreaCurrentFireSeasonLayer;
