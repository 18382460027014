import { useEffect } from "react";
import { lightningLayer, MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import useMapContext from "../Map/useMapContext";

const LIGHTNING_QUERY = buildMapboxBBoxQuery({
  SERVICE: "WMS",
  REQUEST: "GetMap",
  FORMAT: "image/png",
  TRANSPARENT: "TRUE",
  STYLES: "",
  VERSION: "1.3.0",
  LAYERS: "1,2,4,5",
  WIDTH: "512",
  HEIGHT: "512",
  CRS: "EPSG:3857",
});

const LIGHTNING_ID = "lightning";

interface LightningLayerProps {
  opacity?: number;
}

const LightningLayer = ({ opacity = 1 }: LightningLayerProps) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(LIGHTNING_ID, {
      type: "raster",
      tileSize: 512,
      tiles: [
        `${getMapServerProxyBasepath()}/arcgis/services/Reference/Lightning/MapServer/WMSServer?${LIGHTNING_QUERY}`,
      ],
    });
    map.addLayer(
      {
        id: LIGHTNING_ID,
        type: "raster",
        source: LIGHTNING_ID,
      },
      MapLevel.SYMBOLS,
    );

    return () => {
      map.removeLayer(LIGHTNING_ID);
      map.removeSource(LIGHTNING_ID);
    };
  }, [map]);

  useEffect(() => {
    map?.setPaintProperty(LIGHTNING_ID, "raster-opacity", opacity);
  }, [map, opacity]);

  return null;
};

const Lightning = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(lightningLayer.id)) {
    return null;
  }

  const state = getLayerState(lightningLayer.id);

  return <LightningLayer opacity={state?.opacity} />;
};

export default Lightning;
