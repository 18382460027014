import { useEffect } from "react";
import { FIRE_WEATHER_URL } from "../../../config/general";
import { MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import useMapContext from "../Map/useMapContext";

const QUERY = buildMapboxBBoxQuery({
  SERVICE: "WMS",
  REQUEST: "GetMap",
  FORMAT: "image/png",
  TRANSPARENT: "TRUE",
  STYLES: "",
  VERSION: "1.3.0",
  WIDTH: "512",
  HEIGHT: "512",
  CRS: "EPSG:3857",
});

interface FireConditionsRasterViewerProps {
  dimIssueTime?: string | null;
  layer: string;
  opacity?: number;
  time?: string | null;
}

const FireConditionsRasterViewer = ({
  dimIssueTime,
  layer,
  opacity = 1,
  time,
}: FireConditionsRasterViewerProps) => {
  const map = useMapContext();

  const layerId = `${layer}-layer`;
  const tileKey = `${FIRE_WEATHER_URL}?${QUERY}&LAYERS=${layer}&time=${
    time || ""
  }&DIM_ISSUE_TIME=${dimIssueTime || ""}`;

  useEffect(() => {
    map.addSource(`${layer}-source`, {
      type: "raster",
      tiles: [tileKey],
      tileSize: 512,
    });
    map.addLayer(
      {
        id: layerId,
        type: "raster",
        source: `${layer}-source`,
      },
      MapLevel.BACKGROUND,
    );
    return () => {
      map.removeLayer(layerId);
      map.removeSource(`${layer}-source`);
    };
  }, [layer, layerId, map, tileKey]);

  useEffect(() => {
    map?.setPaintProperty(layerId, "raster-opacity", opacity);
    // tileKey is included in dependencies as the dim issue time is asynchronous
    // when it changes, removing and adding the layer in the above effect will
    // overwrite the opacity.
    // we don't want to include opacity in the above effect because we don't
    // want to remove and add the layer every time the opacity changes.
  }, [layerId, map, opacity, tileKey]);

  return null;
};

export default FireConditionsRasterViewer;
