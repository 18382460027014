import type { Feature } from "geojson";
import type { LngLatLike } from "mapbox-gl";
import { isIncidentPointFeature } from "../IncidentPoint/utils";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";

export interface IncidentInteractionProperties
  extends FeatureInteractionProperties {
  incidentId: string;
  name: string;
}

export type IncidentInteractionState =
  FeatureInteractionState<IncidentInteractionProperties>;

export const getPropertiesFromFeature = (
  feature: Feature,
): IncidentInteractionProperties | null => {
  if (!isIncidentPointFeature(feature)) {
    return null;
  }

  const lngLat = feature.geometry.coordinates as LngLatLike;

  const { incidentId, name } = feature.properties;

  return {
    incidentId,
    lngLat,
    name,
  };
};
