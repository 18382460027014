import type { Feature } from "geojson";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import {
  type MapboxMouseEvent,
  isMultiPolygonFeature,
  isPolygonFeature,
} from "../types";

export interface StateForestPlantationInteractionProperties
  extends FeatureInteractionProperties {
  GazettedSFNo: string;
  OBJECTID: string;
}

export type StateForestPlantationInteractionState =
  FeatureInteractionState<StateForestPlantationInteractionProperties>;

export const getPropertiesFromFeature = (
  feature: Feature,
  event: MapboxMouseEvent | maplibregl.MapLayerMouseEvent,
): StateForestPlantationInteractionProperties | null => {
  if (!isMultiPolygonFeature(feature) && !isPolygonFeature(feature)) {
    return null;
  }

  const { lngLat } = event;

  return {
    GazettedSFNo: feature.properties.GazettedSFNo,
    featureId: `${feature.properties.OBJECTID}`,
    OBJECTID: `${feature.properties.OBJECTID}`,
    lngLat,
  };
};
