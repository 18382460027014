import { noop } from "lodash";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import type { alertStatusStateLayers } from "./utils";

interface AlertStatusStateLayerProps {
  layer: keyof typeof alertStatusStateLayers;
  opacity?: number;
}

const getAlertStatusStateGisUrls = () => {
  const baseUrl = getMapServerProxyBasepath();

  return {
    legendUrl: `${baseUrl}/arcgis/rest/services/Operations/AlertStatus/MapServer/legend`,
    tileUrl: `${baseUrl}/arcgis/rest/services/Operations/AlertStatus/MapServer/export`,
  };
};

const AlertStatusStateLayer = (props: AlertStatusStateLayerProps) => {
  const layerId = `alert-status-state-${props.layer}`;

  const map = useMapContext();
  const accessToken = useAuthAccessToken();
  const { tileUrl } = getAlertStatusStateGisUrls();

  useEffect(() => {
    if (!accessToken) return noop;

    const controller = new AbortController();
    controller.signal.addEventListener("abort", () => {
      if (map?.getLayer(layerId)) {
        map?.removeLayer(layerId);
      }

      if (map?.getSource(layerId)) {
        map?.removeSource(layerId);
      }
    });

    if (!map?.getSource(layerId)) {
      const query = buildMapboxBBoxQuery({
        dpi: "96",
        format: "png8",
        layers: `show:${props.layer}`,
        transparent: "true",
        bboxSR: "3857",
        imageSR: "3857",
        size: "512,512",
        f: "image",
      });

      map?.addSource(layerId, {
        type: "raster",
        tileSize: 512,
        tiles: [`${tileUrl}?${query}`],
      });
    }

    if (!map?.getLayer(layerId)) {
      map?.addLayer(
        { id: layerId, type: "raster", source: layerId },
        MapLevel.SYMBOLS,
      );
    }

    return () => controller.abort();
  }, [accessToken, layerId, map, props.layer, tileUrl]);

  useEffect(() => {
    map?.setPaintProperty(layerId, "raster-opacity", props.opacity);
  }, [layerId, map, props.opacity]);

  return null;
};

export default AlertStatusStateLayer;
