import type { Feature, Geometry } from "geojson";
import type { LngLatLike } from "maplibre-gl";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import {
  type MapboxMouseEvent,
  isGeometryCollectionGeometry,
  isPointGeometry,
} from "../types";
import type {
  AusGeocode,
  InterstateIncidentFeature,
} from "./useInterstateIncidentsQuery";

export interface InterstateIncidentInteractionProperties
  extends FeatureInteractionProperties {
  data: InterstateIncidentData;
}

export interface InterstateIncidentData {
  category?: string;
  description: string;
  geocode: AusGeocode;
  title: string;
}

export type InterstateIncidentInteractionState =
  FeatureInteractionState<InterstateIncidentInteractionProperties>;

export const getPropertiesFromFeature = (
  feature: Feature,
  event: MapboxMouseEvent | maplibregl.MapLayerMouseEvent,
): InterstateIncidentInteractionProperties | null => {
  const interstateIncidentFeature = feature as InterstateIncidentFeature;
  const lngLat =
    getLngLatFromGeometry(interstateIncidentFeature.geometry) || event.lngLat;

  return {
    data: {
      category: interstateIncidentFeature.properties.category ?? undefined,
      description: interstateIncidentFeature.properties.description,
      geocode: interstateIncidentFeature.properties.geocodes["ISO3166-2"],
      title: interstateIncidentFeature.properties.title,
    },
    featureId: interstateIncidentFeature.id?.toString(),
    lngLat,
  };
};

const getLngLatFromGeometry = (geometry: Geometry): LngLatLike | null => {
  if (isPointGeometry(geometry)) {
    return geometry.coordinates as LngLatLike;
  }

  if (isGeometryCollectionGeometry(geometry)) {
    for (const g of geometry.geometries) {
      const lngLat = getLngLatFromGeometry(g);
      if (lngLat) {
        return lngLat;
      }
    }
  }

  return null;
};
