import type { Feature } from "geojson";
import type { BrigadeCoverageSnapshot } from "../../../../.rest-hooks/types/coverage.yml";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import {
  type MapboxMouseEvent,
  isMultiPolygonFeature,
  isPolygonFeature,
} from "../types";

export interface BrigadeInteractionProperties
  extends FeatureInteractionProperties {
  brigadeId: string | undefined;
  brigadeName: string;
  coverage: BrigadeCoverageSnapshot | string | undefined;
  districtId: string;
}

export type BrigadeInteractionState =
  FeatureInteractionState<BrigadeInteractionProperties>;

export const getPropertiesFromFeature = (
  feature: Feature,
  event: MapboxMouseEvent | maplibregl.MapLayerMouseEvent,
): BrigadeInteractionProperties | null => {
  if (!isMultiPolygonFeature(feature) && !isPolygonFeature(feature)) {
    return null;
  }

  const { lngLat } = event;
  const { brigadeId, brigadeName, coverage, districtId } = feature.properties;

  return {
    brigadeId,
    brigadeName,
    coverage,
    districtId,
    featureId: brigadeId,
    lngLat,
  };
};
