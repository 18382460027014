import styled from "styled-components";
import type { BurntAreaHazardReductionInteractionState } from "../../map/BurntAreaHazardReduction/interactions";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import BurntAreaHazardReductionPopupDetail from "./BurntAreaHazardReductionPopupDetail";

const StyledHeader = styled.div`
  ${(p) => p.theme.typography.variants.eyebrowDefault};
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  margin: auto 0;
`;

export interface BurntAreaHazardReductionPopupProps {
  "data-testid"?: string;
  onClose: () => void;
  state: BurntAreaHazardReductionInteractionState;
}

const BurntAreaHazardReductionPopup = ({
  "data-testid": dataTestId,
  onClose,
  state: { id: featureId, isActive, properties },
}: BurntAreaHazardReductionPopupProps) => {
  return (
    <SpatialPopup
      isOpen={isActive}
      lngLat={properties?.lngLat}
      onClose={onClose}
      popupConfig={{
        id: featureId,
        offset: 16,
        size: "lg",
        type: "click",
      }}
    >
      {properties && (
        <MapPopupView
          data-testid={dataTestId}
          header={<StyledHeader>Burnt Area - Hazard Reduction</StyledHeader>}
          onClose={onClose}
        >
          <BurntAreaHazardReductionPopupDetail properties={properties} />
        </MapPopupView>
      )}
    </SpatialPopup>
  );
};

export default BurntAreaHazardReductionPopup;
