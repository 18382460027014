import type { Feature } from "geojson";
import type { LngLatLike } from "mapbox-gl";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isPointFeature } from "../types";

export interface TelephoneExchangeData {
  exchgename: string;
  locality: string;
  srcagency: string;
  staddrtag: string;
}

export function getPropertiesFromFeature(
  feature: Feature,
): TelephoneExchangeInteractionProperties | null {
  if (!isPointFeature(feature)) {
    return null;
  }

  const lngLat = feature.geometry.coordinates as LngLatLike;

  return {
    data: {
      exchgename: feature.properties?.exchgename ?? "",
      locality: feature.properties?.locality ?? "",
      srcagency: feature.properties?.srcagency ?? "",
      staddrtag: feature.properties?.staddrtag ?? "",
    },
    lngLat,
  };
}

export interface TelephoneExchangeInteractionProperties
  extends FeatureInteractionProperties {
  data: TelephoneExchangeData;
}

export type TelephoneExchangeInteractionState =
  FeatureInteractionState<TelephoneExchangeInteractionProperties>;
