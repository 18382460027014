import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import { useMapServerQueryData } from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";

const ACCOMMODATION_ID = "accommodationFacilities";

export const ACCOMMODATION_FACILITIES = {
  ACCOMMODATION_FACILITY_CAMPING_GROUND:
    "/icons/accommodation-facilities-camping-grounds.png",
  ACCOMMODATION_FACILITY_CARAVAN_PARK:
    "/icons/accommodation-facilities-caravan-park.png",
  ACCOMMODATION_FACILITY_CLUB: "/icons/accommodation-facilities-club.png",
  ACCOMMODATION_FACILITY_HOTEL: "/icons/accommodation-facilities-hotel.png",
  ACCOMMODATION_FACILITY_UNKNOWN: "/icons/accommodation-facilities-unknown.png",
};

interface AccommodationFacilitiesLayerProps {
  opacity?: number;
}

const AccommodationFacilitiesLayer = ({
  opacity = 1,
}: AccommodationFacilitiesLayerProps) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(
    `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/BaseDynamicData/MapServer/13/query`,
  );

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(ACCOMMODATION_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    Promise.all(
      Object.entries(ACCOMMODATION_FACILITIES).map(([imageId, src]) =>
        loadImage({ imageId, map, signal: controller.signal, src }),
      ),
    ).then(() => {
      map.addLayer(
        {
          id: ACCOMMODATION_ID,
          type: "symbol",
          source: ACCOMMODATION_ID,
          layout: {
            "icon-image": [
              "coalesce",
              ["image", ["get", "symbolname"]],
              ["image", "ACCOMMODATION_FACILITY_UNKNOWN"],
            ],
            "icon-allow-overlap": true,
            "icon-optional": false,
          },
        },
        MapLevel.SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      controller.abort();
      if (map.getLayer(ACCOMMODATION_ID)) {
        map.removeLayer(ACCOMMODATION_ID);
      }
      map.removeSource(ACCOMMODATION_ID);
      Object.keys(ACCOMMODATION_FACILITIES).forEach((id) => {
        map.removeImage(id);
      });
    };
  }, [map]);

  useEffect(() => {
    const source = map?.getSource(ACCOMMODATION_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  useEffect(() => {
    map.setPaintProperty(ACCOMMODATION_ID, "icon-opacity", opacity);
  }, [opacity, map]);

  return null;
};

export default AccommodationFacilitiesLayer;
