import { useEffect } from "react";
import { useGetSourcesActAvl } from "../../../../.rest-hooks/sources";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import useMapContext from "../Map/useMapContext";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";
import type { ACTESAResourcesFeatureProperties } from "./interactions";

const ACT_VEHICLES_ICONS = {
  AS: "/icons/as-act-ambulance-service.png",
  FaRS: "/icons/fars-act-fire-and-rescue.png",
  RFS: "/icons/rfs-act-rural-fire-service.png",
  SES: "/icons/ses-act-state-emergency-service.png",
};

interface UseACTESAResourcesMapLayerParams {
  layerId: string;
  opacity?: number;
}

const useACTESAResourcesMapLayer = ({
  layerId,
  opacity = 1,
}: UseACTESAResourcesMapLayerParams) => {
  const map = useMapContext();
  const { data } = useGetSourcesActAvl({
    query: {
      select: (response) =>
        response.data.data.attributes.geojson as GeoJSON.FeatureCollection<
          GeoJSON.Point,
          ACTESAResourcesFeatureProperties
        >,
    },
  });

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(layerId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    Promise.all(
      Object.entries(ACT_VEHICLES_ICONS).map(([imageId, src]) =>
        loadImage({
          imageId,
          map,
          src,
          signal: controller.signal,
        }),
      ),
    ).then(() => {
      map.addLayer(
        {
          id: layerId,
          type: "symbol",
          source: layerId,
          layout: {
            "icon-image": ["coalesce", ["image", ["get", "AGC"]]],
            "icon-allow-overlap": true,
            "icon-optional": false,
          },
        },
        MapLevel.SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
      map.removeSource(layerId);
      Object.keys(ACT_VEHICLES_ICONS).forEach((id) => {
        if (map.hasImage(id)) {
          map.removeImage(id);
        }
      });
      controller.abort();
    };
  }, [layerId, map]);

  useEffect(() => {
    const source = map.getSource(layerId);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, layerId, map]);

  useEffect(() => {
    map.setPaintProperty(layerId, "icon-opacity", opacity);
  }, [layerId, map, opacity]);

  return null;
};

export default useACTESAResourcesMapLayer;
