import type { Feature } from "geojson";
import type { LngLatLike } from "maplibre-gl";
import type { FeatureInteractionProperties } from "../../MapInteractions/types";
import { isPointFeature } from "../../types";
import type { FieldObsWeatherData } from "../types";
import {
  FIELD_OBS_WEATHER_WIND_DIRECTION_CODED_VALUES,
  FIELD_OBS_WEATHER_WIND_TREND_CODED_VALUES,
  type FieldObsWeatherFeature,
} from "./gis";

export interface FieldObsWeatherInteractionProperties
  extends FeatureInteractionProperties {
  data: FieldObsWeatherData;
  globalId: string;
  objectId: number | string;
}

export const getPropertiesFromFeature = (
  feature: Feature,
): FieldObsWeatherInteractionProperties | null => {
  if (!isPointFeature(feature)) {
    return null;
  }

  const weatherFeature = feature as FieldObsWeatherFeature;
  const lngLat = weatherFeature.geometry.coordinates as LngLatLike;
  const {
    OBJECTID,
    GlobalID,
    Humidity,
    Temperature,
    WindDirection,
    WindSpeed,
    WindTrend,
  } = weatherFeature.properties;

  return {
    data: {
      humidityPercent: Humidity ?? undefined,
      temperatureCelsius: Temperature ?? undefined,
      windDirection: formatCodedValue(
        FIELD_OBS_WEATHER_WIND_DIRECTION_CODED_VALUES,
        WindDirection,
      ),
      windSpeedKmh: WindSpeed ?? undefined,
      windTrend: formatCodedValue(
        FIELD_OBS_WEATHER_WIND_TREND_CODED_VALUES,
        WindTrend,
      ),
    },
    featureId: weatherFeature.id?.toString(),
    globalId: GlobalID,
    objectId: OBJECTID,
    lngLat,
  };
};

const formatCodedValue = (
  map: Partial<Record<number | string, string>>,
  code?: number | string | null,
): string | undefined => {
  if (code == null) {
    return undefined;
  }

  return code in map ? map[code] : code.toString();
};
