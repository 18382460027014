import {
  Divider,
  FieldGrid,
  useIsMinWidth,
  useTheme,
  type ButtonSize,
} from "@app/design-system";
import type { LngLatLike } from "mapbox-gl";
import { useCollapse } from "react-collapsed";
import { useGetPredictionsId } from "../../../../.rest-hooks/predictions";
import type { Incident } from "../../../../.rest-hooks/types";
import { formatIncidentStatus } from "../../../config/general";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import getIconUrl from "../../../utils/getIconUrl";
import { INCIDENT_POINT_COLLECTION_LAYER_ID } from "../../map/IncidentPointCollection/constants";
import type { IncidentInteractionProperties } from "../../map/IncidentPointCollection/interactions";
import useUnsafeMapContext from "../../map/Map/useUnsafeMapContext";
import useLayerInteractionState from "../../map/MapInteractions/useLayerInteractionState";
import IncidentPriorityIndicator from "../../ui/IncidentPriorityIndicator/IncidentPriorityIndicator";
import PreferredPredictionValidIndicator, {
  type PreferredPredictionAttribute,
} from "../../ui/PreferredPredictionValidIndicator/PreferredPredictionValidIndicator";
import CompactIncidentCardHeader from "./CompactIncidentCardHeader";
import ImpactProbabilitiesSummary from "./ImpactProbabilitiesSummary";
import IncidentCardHeader from "./IncidentCardHeader";
import {
  StyledBody,
  StyledContent,
  StyledContentInner,
  StyledFields,
  StyledFooter,
  StyledIncidentCard,
  StyledTitle,
} from "./styled";

export type IncidentCardLayout = "card" | "list";

export interface IncidentCtaProps {
  fullWidth?: boolean;
  incidentId: string;
  size?: ButtonSize;
}

interface IncidentCardProps {
  cta: React.ComponentType<IncidentCtaProps>;
  "data-testid"?: string;
  defaultExpanded?: boolean;
  incident: Incident;
  isCopView?: boolean;
  layout?: IncidentCardLayout;
  onLocateClick?: () => void;
  predictionAttribute: PreferredPredictionAttribute;
}

const IncidentCard = ({
  cta: Cta,
  "data-testid": dataTestId,
  defaultExpanded,
  incident,
  isCopView = false,
  layout = "card",
  onLocateClick,
  predictionAttribute,
}: IncidentCardProps) => {
  const theme = useTheme();
  const { map } = useUnsafeMapContext();

  const isTabletLandscapeAndAbove = useIsMinWidth("lg");

  const preferredPredictionId = incident.attributes.preferredPrediction;

  const { activateHoverState, deactivateHoverState } =
    useLayerInteractionState<IncidentInteractionProperties>({
      layerId: INCIDENT_POINT_COLLECTION_LAYER_ID,
    });

  const onMouseEnter = (isActive: boolean) => () => {
    if (!map || !isTabletLandscapeAndAbove) return;

    // Activating the hover state when the feature isn't actually within view
    // breaks the scroll behaviour of the page by rendering the HTML for the
    // popup off-screen.

    if (isActive) {
      activateHoverState({
        incidentId: incident.id,
        lngLat: incident.attributes.location.coordinates as LngLatLike,
        name: incident.attributes.name,
      });
    } else {
      deactivateHoverState();
    }
  };

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: theme.anim.duration.sm,
    easing: theme.anim.curve,
    defaultExpanded,
  });

  const predictionQuery = useGetPredictionsId(preferredPredictionId!, {
    query: {
      enabled: !isCopView && !!preferredPredictionId && isExpanded,
      select: (data) => data.data.data,
    },
  });

  const incidentName = incident.attributes.name || <>&mdash;</>;

  const title = (
    <StyledTitle onClick={onLocateClick} type="button">
      {incidentName}
    </StyledTitle>
  );

  return (
    <StyledIncidentCard
      onMouseEnter={onMouseEnter(true)}
      onMouseLeave={onMouseEnter(false)}
      data-testid={dataTestId}
    >
      {layout === "card" ? (
        <IncidentCardHeader
          incident={incident}
          isCopView={isCopView}
          isExpanded={isExpanded}
          title={title}
          data-testid={dataTestId}
          toggleProps={getToggleProps()}
        />
      ) : (
        <CompactIncidentCardHeader
          incident={incident}
          isCopView={isCopView}
          isExpanded={isExpanded}
          title={title}
          data-testid={dataTestId}
          toggleProps={getToggleProps()}
        />
      )}
      <StyledContent {...getCollapseProps()}>
        <Divider variant="weak" />
        <StyledContentInner>
          <StyledBody>
            <StyledFields>
              {!isCopView && (
                <PreferredPredictionValidIndicator
                  attribute={predictionAttribute}
                  enabled={isExpanded}
                  predictionId={preferredPredictionId}
                />
              )}
              <FieldGrid>
                <FieldGrid.Item label="Status:">
                  {formatIncidentStatus(incident.attributes.status)}
                </FieldGrid.Item>
                {!isCopView && (
                  <FieldGrid.Item label="Risk rating:">
                    <IncidentPriorityIndicator
                      errorVariant="tooltip"
                      incident={incident}
                    />
                  </FieldGrid.Item>
                )}
                <FieldGrid.Item label="Assigned to:">
                  {incident.attributes.leadAgency || "—"}
                </FieldGrid.Item>
                <FieldGrid.Item label="Type:">
                  {incident.attributes.types.length
                    ? incident.attributes.types.join(", ")
                    : "—"}
                </FieldGrid.Item>
                <FieldGrid.Item label="Size:">
                  {incident.attributes.burntArea ?? "—"}
                </FieldGrid.Item>
                <FieldGrid.Item label="Last updated:">
                  {getFormattedDateAndTime(
                    incident.attributes.updatedAt * 1000,
                  )}
                </FieldGrid.Item>
                <FieldGrid.Item label="ICON:">
                  <div>
                    <FieldGrid.Anchor
                      href={getIconUrl(incident.attributes.iconId)}
                      external
                      rel="noreferrer"
                      target="_blank"
                      title="View in ICON"
                    >
                      {incident.attributes.iconId}
                    </FieldGrid.Anchor>
                  </div>
                </FieldGrid.Item>
              </FieldGrid>
            </StyledFields>
            {isTabletLandscapeAndAbove &&
              !isCopView &&
              !!preferredPredictionId &&
              predictionQuery.data && (
                <ImpactProbabilitiesSummary prediction={predictionQuery.data} />
              )}
          </StyledBody>
          {!isCopView && (
            <StyledFooter>
              <Cta
                fullWidth={!isTabletLandscapeAndAbove}
                incidentId={incident.id}
                size="sm"
              />
            </StyledFooter>
          )}
        </StyledContentInner>
      </StyledContent>
    </StyledIncidentCard>
  );
};

export default IncidentCard;
