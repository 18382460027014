import type { Feature } from "geojson";
import type { LngLatLike } from "mapbox-gl";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isPointFeature } from "../types";

export interface NeighbourhoodSaferPlacesData {
  Location: string;
  Title: string;
  Type: string;
}

export function getPropertiesFromFeature(
  feature: Feature,
): NeighbourhoodInteractionProperties | null {
  if (!isPointFeature(feature)) {
    return null;
  }

  const lngLat = feature.geometry.coordinates as LngLatLike;

  return {
    data: {
      Location: feature.properties?.Location ?? "",
      Title: feature.properties?.Title ?? "",
      Type: feature.properties?.Type ?? "",
    },
    lngLat,
  };
}

export interface NeighbourhoodInteractionProperties
  extends FeatureInteractionProperties {
  data: NeighbourhoodSaferPlacesData;
}

export type NeighbourhoodInteractionState =
  FeatureInteractionState<NeighbourhoodInteractionProperties>;
