import type { TelephoneExchangeInteractionState } from "../../map/TelephoneExchange/interactions";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import TelephoneExchangePopupDetail from "./TelephoneExchangePopupDetail";

export interface TelephoneExchangePopupProps {
  "data-testid"?: string;
  onClose: () => void;
  state: TelephoneExchangeInteractionState;
}

const TelephoneExchangePopup = ({
  "data-testid": dataTestId,
  onClose,
  state: { id: featureId, isActive, properties },
}: TelephoneExchangePopupProps) => {
  return (
    <SpatialPopup
      isOpen={isActive}
      lngLat={properties?.lngLat}
      onClose={onClose}
      popupConfig={{
        id: featureId,
        offset: 16,
        size: "lg",
        type: "click",
      }}
    >
      {properties && (
        <MapPopupView data-testid={dataTestId} onClose={onClose}>
          <TelephoneExchangePopupDetail properties={properties} />
        </MapPopupView>
      )}
    </SpatialPopup>
  );
};

export default TelephoneExchangePopup;
