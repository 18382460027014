import { useTheme } from "@app/design-system";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import { InteractionStateType } from "../MapInteractions/types";
import useInteractionFeatureState from "../MapInteractions/useInteractionFeatureState";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import {
  useMapServerQueryData,
  type QueryOptions,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { WILDFIRE_COLOR_SCALE } from "./WildfireHistory";
import WildfireHistoryPopup, {
  getPropertiesFromFeature,
} from "./WildfireHistoryPopup";

const WILDFIRE_HISTORY_ID = "wildfire-history-seven-plus";
const WILDFIRE_HISTORY_OUTLINE_ID = `${WILDFIRE_HISTORY_ID}-outline`;

const WILDFIRE_HISTORY_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/WildfireHistory/MapServer/6/query`;
const QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    outFields: "FIRESEASON,FIRENAME,YEAROFFIRE,OBJECTID",
  },
};

interface SevenPlusYearsLayerProps {
  opacity?: number;
}

const SevenPlusYearsLayer = ({ opacity = 1 }: SevenPlusYearsLayerProps) => {
  const theme = useTheme();
  const map = useMapContext();
  const { data } = useMapServerQueryData(WILDFIRE_HISTORY_URL, QUERY_OPTIONS);

  useEffect(() => {
    map.addSource(WILDFIRE_HISTORY_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: WILDFIRE_HISTORY_ID,
        type: "fill",
        source: WILDFIRE_HISTORY_ID,
        paint: {
          "fill-color": [
            "match",
            ["get", "FIRESEASON"],
            ...WILDFIRE_COLOR_SCALE,
          ],
        },
        minzoom: 8,
      },
      MapLevel.BACKGROUND,
    );

    map.addLayer({
      id: WILDFIRE_HISTORY_OUTLINE_ID,
      type: "line",
      source: WILDFIRE_HISTORY_ID,
      paint: {
        "line-color": theme.colors.decorative.manualBorderMedium,
        "line-width": 3,
        "line-opacity": [
          "case",
          [
            "any",
            ["boolean", ["feature-state", InteractionStateType.HOVERED], false],
            ["boolean", ["feature-state", InteractionStateType.CLICKED], false],
          ],
          1,
          0,
        ],
      },
    });

    return () => {
      map.removeLayer(WILDFIRE_HISTORY_ID);
      map.removeLayer(WILDFIRE_HISTORY_OUTLINE_ID);
      map.removeSource(WILDFIRE_HISTORY_ID);
    };
  }, [map, theme.colors.decorative.manualBorderMedium]);

  useEffect(() => {
    map?.setPaintProperty(WILDFIRE_HISTORY_ID, "fill-opacity", opacity);
  }, [map, opacity]);

  useEffect(() => {
    const source = map?.getSource(WILDFIRE_HISTORY_ID);

    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  const { clickedState, deactivateClickState, hoveredState } =
    useLayerInteractions({
      getPropertiesFromFeature,
      layerId: WILDFIRE_HISTORY_ID,
    });

  useInteractionFeatureState({
    getFeatureId: (feature) => feature.data.objectId,
    clickedState,
    hoveredState,
    sourceId: WILDFIRE_HISTORY_ID,
  });

  return (
    <WildfireHistoryPopup
      label="Wildfire history 7+ years"
      onClose={deactivateClickState}
      state={clickedState}
    />
  );
};

export default SevenPlusYearsLayer;
