import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";

const RFS_DISTRICTS_ID = "RFSDistricts";
const RFS_DISTRICTS_TEXT_ID = `${RFS_DISTRICTS_ID}-text`;

const RFS_DISTRICTS_QUERY_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/BaseDynamicData/MapServer/24/query`;
const QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    outFields: "DISTRICT",
  },
};

interface RFSDistrictsLayerProps {
  opacity?: number;
}

const RFSDistrictsLayer = ({ opacity = 1 }: RFSDistrictsLayerProps) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(
    RFS_DISTRICTS_QUERY_URL,
    QUERY_OPTIONS,
  );

  useEffect(() => {
    map.addSource(RFS_DISTRICTS_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: RFS_DISTRICTS_ID,
        type: "line",
        source: RFS_DISTRICTS_ID,
        paint: {
          "line-color": "#393636",
          "line-width": 1,
        },
      },
      MapLevel.BACKGROUND,
    );

    map.addLayer(
      {
        id: RFS_DISTRICTS_TEXT_ID,
        type: "symbol",
        source: RFS_DISTRICTS_ID,
        layout: {
          "text-field": ["get", "DISTRICT"],
          "symbol-placement": "point",
        },
      },
      MapLevel.BACKGROUND,
    );

    return () => {
      map.removeLayer(RFS_DISTRICTS_ID);
      map.removeLayer(RFS_DISTRICTS_TEXT_ID);
      map.removeSource(RFS_DISTRICTS_ID);
    };
  }, [map]);

  useEffect(() => {
    const source = map?.getSource(RFS_DISTRICTS_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  useEffect(() => {
    map.setPaintProperty(RFS_DISTRICTS_TEXT_ID, "text-opacity", opacity);
    map.setPaintProperty(RFS_DISTRICTS_ID, "line-opacity", opacity);
  }, [map, opacity]);

  return null;
};

export default RFSDistrictsLayer;
