import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import { useMapServerQueryData } from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";

const EDUCATION_FACILITIES_ID = "educationFacilities";

const EDUCATION_FACILITIES = {
  EDUCATION_FACILITY_CHILD_CARE_CENTRE:
    "/icons/education-facility-child-care-centre.png",
  EDUCATION_FACILITY_COLLEGE: "/icons/education-facility-college.png",
  EDUCATION_FACILITY_KINDERGARTEN: "/icons/education-facility-kindergarten.png",
  EDUCATION_FACILITY_PRIMARY: "/icons/education-facility-primary.png",
  EDUCATION_FACILITY_SECONDARY: "/icons/education-facility-secondary.png",
  EDUCATION_FACILITY_TAFE: "/icons/education-facility-tafe.png",
  EDUCATION_FACILITY_UNIVERSITY: "/icons/education-facility-university.png",
  EDUCATION_FACILITY_UNKNOWN: "/icons/education-facility-unknown.png",
};

interface EducationFacilitiesLayerProps {
  opacity?: number;
}

const EducationFacilitiesLayer = ({
  opacity = 1,
}: EducationFacilitiesLayerProps) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(
    `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/BaseDynamicData/MapServer/11/query`,
  );

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(EDUCATION_FACILITIES_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    Promise.all(
      Object.entries(EDUCATION_FACILITIES).map(([imageId, src]) =>
        loadImage({ imageId, map, src, signal: controller.signal }),
      ),
    ).then(() => {
      map.addLayer(
        {
          id: EDUCATION_FACILITIES_ID,
          type: "symbol",
          source: EDUCATION_FACILITIES_ID,
          layout: {
            "icon-image": [
              "coalesce",
              ["image", ["get", "symbolname"]],
              ["image", "EDUCATION_FACILITY_UNKNOWN"],
            ],
            "icon-allow-overlap": true,
            "icon-optional": false,
          },
        },
        MapLevel.SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      controller.abort();
      if (map.getLayer(EDUCATION_FACILITIES_ID)) {
        map.removeLayer(EDUCATION_FACILITIES_ID);
      }
      map.removeSource(EDUCATION_FACILITIES_ID);
      Object.keys(EDUCATION_FACILITIES).forEach((id) => {
        if (map.hasImage(id)) {
          map.removeImage(id);
        }
      });
    };
  }, [map]);

  useEffect(() => {
    const source = map.getSource(EDUCATION_FACILITIES_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  useEffect(() => {
    map.setPaintProperty(EDUCATION_FACILITIES_ID, "icon-opacity", opacity);
  }, [opacity, map]);

  return null;
};

export default EducationFacilitiesLayer;
