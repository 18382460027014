import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";

const FIELD_OBS_ICON_UNDER_1_HR_QUERY = buildMapboxBBoxQuery({
  dpi: "96",
  format: "png32",
  transparent: "true",
  bboxSR: "3857",
  imageSR: "3857",
  size: "512,512",
  f: "image",
});

const FIELD_OBS_ICON_UNDER_1_HR_ID = "fieldObsIconUnder1Hr";
const FIELD_OBS_ICON_UNDER_1_HR_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/FieldObs/FieldObsPresentation1Hr/MapServer/export?${FIELD_OBS_ICON_UNDER_1_HR_QUERY}`;

interface FieldObsIconUnder1HrLayerProps {
  opacity?: number;
}

const FieldObsIconUnder1HrLayer = ({
  opacity = 1,
}: FieldObsIconUnder1HrLayerProps) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(FIELD_OBS_ICON_UNDER_1_HR_ID, {
      type: "raster",
      tileSize: 512,
      tiles: [FIELD_OBS_ICON_UNDER_1_HR_URL],
    });

    map.addLayer(
      {
        id: FIELD_OBS_ICON_UNDER_1_HR_ID,
        type: "raster",
        source: FIELD_OBS_ICON_UNDER_1_HR_ID,
      },
      MapLevel.SYMBOLS,
    );

    return () => {
      map.removeLayer(FIELD_OBS_ICON_UNDER_1_HR_ID);
      map.removeSource(FIELD_OBS_ICON_UNDER_1_HR_ID);
    };
  }, [map]);

  useEffect(() => {
    map.setPaintProperty(
      FIELD_OBS_ICON_UNDER_1_HR_ID,
      "raster-opacity",
      opacity,
    );
  }, [map, opacity]);

  return null;
};

export default FieldObsIconUnder1HrLayer;
