import { Text } from "@app/design-system";
import styled from "styled-components";
import type { InterstateIncidentInteractionState } from "../../map/InterstateIncidents/interactions";
import RawHtml from "../../util/RawHtml/RawHtml";
import type { MapPopupType } from "../SpatialPopup/MapPopup";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";

const StyledTitle = styled.div`
  margin: auto 0;
  ${(p) => p.theme.typography.variants.subtitleMd};
`;

const StyledContent = styled.div`
  ${(p) => p.theme.typography.variants.bodyDefault};
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  white-space: pre-line;
`;

export interface InterstateIncidentPopupProps {
  descriptionRawHtml: string;
  onClose: () => void;
  state: InterstateIncidentInteractionState;
  title: string;
  type: MapPopupType;
}

const InterstateIncidentPopup = ({
  descriptionRawHtml,
  onClose,
  state: { id: featureId, isActive, properties },
  title,
  type,
}: InterstateIncidentPopupProps) => {
  return (
    <SpatialPopup
      isOpen={isActive}
      onClose={onClose}
      popupConfig={{
        id: featureId,
        offset: 32,
        type,
      }}
      lngLat={properties?.lngLat}
    >
      <MapPopupView
        header={<StyledTitle>{title}</StyledTitle>}
        onClose={onClose}
      >
        <Text size="bodyDefaultStrong">Description:</Text>
        <StyledContent>
          <RawHtml allowLinks={false} html={descriptionRawHtml} />
        </StyledContent>
      </MapPopupView>
    </SpatialPopup>
  );
};

export default InterstateIncidentPopup;
