import { showToast } from "@app/design-system";
import useAttachmentsQuery from "../../../hooks/useAttachmentsQuery";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import { downloadFile } from "../../../utils/downloadFile";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import type { FieldObsWeatherInteractionState } from "../../map/FieldObsWeather/types";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import FieldObsWeatherPopupView, {
  type FieldObsWeatherAttachment,
} from "./FieldObsWeatherPopupView";
import type { FieldObsWeatherField } from "./constants";

export interface FieldObsWeatherPopupProps {
  fields: Record<FieldObsWeatherField, string>;
  onClose: () => void;
  state: FieldObsWeatherInteractionState;
  title: string;
  sourceLayerUrlPath: string;
}

const FieldObsWeatherPopup = ({
  fields,
  onClose,
  sourceLayerUrlPath,
  state: { id: featureId, isActive, properties },
  title,
}: FieldObsWeatherPopupProps) => {
  const accessToken = useAuthAccessToken();
  const { isError: isAttachmentsError, data: attachmentsData } =
    useAttachmentsQuery({
      objectId: properties?.objectId.toString(),
      urlPath: sourceLayerUrlPath,
    });

  const onDownloadAttachment = ({ id, name }: FieldObsWeatherAttachment) => {
    const errorToastProps = {
      id: "download-error",
      title: `Unable to download attachment "${name}"`,
      variant: "error",
    } as const;

    if (!properties) {
      showToast(errorToastProps);
      return;
    }

    downloadFile({
      accessToken,
      filename: name,
      url: `${getMapServerProxyBasepath()}${sourceLayerUrlPath}/${
        properties.objectId
      }/attachments/${id}`,
    }).catch(() => {
      showToast(errorToastProps);
    });
  };

  return (
    <SpatialPopup
      isOpen={isActive}
      onClose={onClose}
      popupConfig={{
        id: featureId,
        offset: 32,
        type: "click",
      }}
      lngLat={properties?.lngLat}
    >
      <FieldObsWeatherPopupView
        attachments={attachmentsData?.attachmentInfos}
        fields={fields}
        isAttachmentsError={isAttachmentsError}
        onClose={onClose}
        onDownloadAttachment={onDownloadAttachment}
        properties={properties}
        title={title}
      />
    </SpatialPopup>
  );
};

export default FieldObsWeatherPopup;
