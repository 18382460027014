import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import useMapContext from "../Map/useMapContext";

interface UseAVLPositionsMapLayersParams {
  layerId: string;
  opacity?: number;
  sourceId: string;
}

const useAVLPositionsMapLayers = ({
  layerId,
  opacity = 1,
  sourceId,
}: UseAVLPositionsMapLayersParams) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(sourceId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: layerId,
        type: "symbol",
        source: sourceId,
        layout: {
          "icon-image": [
            "coalesce",
            ["image", ["get", "StatusCategory"]],
            ["image", "fallback"],
          ],
          "icon-allow-overlap": true,
          "icon-rotate": ["get", "DirectionHor"],
          "icon-rotation-alignment": "map",
        },
      },
      MapLevel.FS_SYMBOLS,
    );

    return () => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    };
  }, [layerId, map, sourceId]);

  useEffect(() => {
    map.setPaintProperty(layerId, "icon-opacity", opacity);
  }, [layerId, map, opacity]);
};

export default useAVLPositionsMapLayers;
