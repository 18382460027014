import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";

const AGED_CARE_ID = "agedCareFacilities";

export const AGED_CARE_FACILITIES = {
  HEALTH_FACILITY_AGED_CARE_AND_RESIDENCE:
    "/icons/aged-care-facility-aged-care-and-residents.png",
  HEALTH_FACILITY_NURSING_HOME: "/icons/aged-care-facility-nursing-home.png",
  HEALTH_FACILITY_UNKNOWN: "/icons/aged-care-facility-unknown.png",
};

const AGED_CARE_QUERY_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/BaseDynamicData/MapServer/9/query`;
const QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    outFields: "symbolname",
  },
};

interface AgedCareLayerProps {
  opacity?: number;
}

const AgedCareLayer = ({ opacity = 1 }: AgedCareLayerProps) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(AGED_CARE_QUERY_URL, QUERY_OPTIONS);

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(AGED_CARE_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    Promise.all(
      Object.entries(AGED_CARE_FACILITIES).map(([imageId, src]) =>
        loadImage({ imageId, map, src, signal: controller.signal }),
      ),
    ).then(() => {
      map.addLayer(
        {
          id: AGED_CARE_ID,
          type: "symbol",
          source: AGED_CARE_ID,
          layout: {
            "icon-image": [
              "coalesce",
              ["image", ["get", "symbolname"]],
              ["image", "HEALTH_FACILITY_UNKNOWN"],
            ],
            "icon-allow-overlap": true,
            "icon-optional": false,
          },
        },
        MapLevel.SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      controller.abort();
      if (map.getLayer(AGED_CARE_ID)) {
        map.removeLayer(AGED_CARE_ID);
      }
      map.removeSource(AGED_CARE_ID);
      Object.keys(AGED_CARE_FACILITIES).forEach((id) => {
        map.removeImage(id);
      });
    };
  }, [map]);

  useEffect(() => {
    const source = map?.getSource(AGED_CARE_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  useEffect(() => {
    map.setPaintProperty(AGED_CARE_ID, "icon-opacity", opacity);
  }, [opacity, map]);

  return null;
};

export default AgedCareLayer;
