import { useEffect } from "react";
import { useGetIncidentsAviationRiskRatings } from "../../../../.rest-hooks/risk-assessments";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import useGetIncidentPointCollection from "../IncidentPointCollection/useGetIncidentPointCollection";
import useMapContext from "../Map/useMapContext";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";
import { updateFeaturesWithIcon } from "./utils";

const FIRE_GROUND_FLIGHT_RISK_ID = "fireGroundFlightRisk";

const FIRE_GROUND_FLIGHT_RISK_ICONS = {
  FIRE_GROUND_FLIGHT_RISK_LOW: "/icons/fire-ground-flight-risk-low.png",
  FIRE_GROUND_FLIGHT_RISK_MODERATE:
    "/icons/fire-ground-flight-risk-moderate.png",
  FIRE_GROUND_FLIGHT_RISK_SEVERE: "/icons/fire-ground-flight-risk-severe.png",
};

interface FireGroundFlightRiskLayerProps {
  opacity?: number;
}

const FireGroundFlightRiskLayer = ({
  opacity = 1,
}: FireGroundFlightRiskLayerProps) => {
  const map = useMapContext();
  const incidentPointCollection = useGetIncidentPointCollection();
  const { data } = useGetIncidentsAviationRiskRatings();

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(FIRE_GROUND_FLIGHT_RISK_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    Promise.all(
      Object.entries(FIRE_GROUND_FLIGHT_RISK_ICONS).map(([imageId, src]) =>
        loadImage({ imageId, map, src, signal: controller.signal }),
      ),
    ).then(() => {
      map.addLayer(
        {
          id: FIRE_GROUND_FLIGHT_RISK_ID,
          type: "symbol",
          source: FIRE_GROUND_FLIGHT_RISK_ID,
          layout: {
            "icon-image": ["get", "icon"],
            "icon-size": 0.5,
            "icon-allow-overlap": true,
          },
        },
        MapLevel.SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      controller.abort();

      if (map.getLayer(FIRE_GROUND_FLIGHT_RISK_ID)) {
        map.removeLayer(FIRE_GROUND_FLIGHT_RISK_ID);
      }
      Object.keys(FIRE_GROUND_FLIGHT_RISK_ICONS).forEach((id) => {
        if (map.hasImage(id)) {
          map.removeImage(id);
        }
      });
      map.removeSource(FIRE_GROUND_FLIGHT_RISK_ID);
    };
  }, [map]);

  useEffect(() => {
    const source = map.getSource(FIRE_GROUND_FLIGHT_RISK_ID);
    const incidentsRiskAssessment = data?.data.data;

    const fireGroundFlightRiskData =
      incidentPointCollection?.features && incidentsRiskAssessment
        ? updateFeaturesWithIcon(
            incidentPointCollection?.features,
            incidentsRiskAssessment,
          )
        : null;
    if (isGeoJsonSource(source) && fireGroundFlightRiskData) {
      source.setData(fireGroundFlightRiskData);
    }
  }, [data, incidentPointCollection, map]);

  useEffect(() => {
    map.setPaintProperty(FIRE_GROUND_FLIGHT_RISK_ID, "icon-opacity", opacity);
  }, [map, opacity]);

  return null;
};

export default FireGroundFlightRiskLayer;
