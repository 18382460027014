import { transformAndCheckStatus } from "@kablamo/kerosene";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import type { Feature, FeatureCollection, Geometry } from "geojson";

export type InterstateIncidentFeatureCollection = FeatureCollection<
  Geometry,
  InterstateIncidentFeatureProps
>;

export type InterstateIncidentFeature = Feature<
  Geometry,
  InterstateIncidentFeatureProps
>;

export interface InterstateIncidentFeatureProps {
  category: string | null;
  description: string;
  geocodes: { "ISO3166-2": AusGeocode };
  link: string;
  pubDate: string;
  title: string;
}

/**
 * ISO 3166-2:AU
 * @see https://en.wikipedia.org/wiki/ISO_3166-2:AU
 */
export type AusGeocode =
  | "AU-ACT"
  | "AU-NSW"
  | "AU-NT"
  | "AU-QLD"
  | "AU-SA"
  | "AU-TAS"
  | "AU-VIC"
  | "AUS-WA";

export interface UseInterstateIncidentsQueryParams {
  url: string;
}

export type UseInterstateIncidentsQueryResult =
  UseQueryResult<InterstateIncidentFeatureCollection>;

const useInterstateIncidentsQuery = ({
  url,
}: UseInterstateIncidentsQueryParams): UseInterstateIncidentsQueryResult => {
  const result = useQuery({
    queryFn: () =>
      fetch(url).then<InterstateIncidentFeatureCollection>(
        transformAndCheckStatus,
      ),
    queryKey: [url],
  });

  return result;
};

export default useInterstateIncidentsQuery;
