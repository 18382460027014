import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import PreviewSpatialPopup from "../../popup/PreviewSpatialPopup/PreviewSpatialPopup";
import useMapContext from "../Map/useMapContext";
import { InteractionStateType } from "../MapInteractions/types";
import useInteractionFeatureState from "../MapInteractions/useInteractionFeatureState";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { getPropertiesFromFeature } from "./interactions";

const STATE_FOREST_PLANTATION_ID = "stateForestPlantation";
const options: QueryOptions = {
  queryParams: {
    outFields: "GazettedSFNo, OBJECTID",
  },
};

const StateForestPlantationLayer = () => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(
    `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/ForestAssets/MapServer/0/query`,
    options,
  );

  useEffect(() => {
    map.addSource(STATE_FOREST_PLANTATION_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: STATE_FOREST_PLANTATION_ID,
        type: "fill",
        source: STATE_FOREST_PLANTATION_ID,
        paint: {
          "fill-color": [
            "case",
            [
              "any",
              [
                "boolean",
                ["feature-state", InteractionStateType.HOVERED],
                false,
              ],
              [
                "boolean",
                ["feature-state", InteractionStateType.CLICKED],
                false,
              ],
            ],
            "#077600",
            "#3AA905",
          ],
        },
      },
      MapLevel.BACKGROUND,
    );

    return () => {
      map.removeLayer(STATE_FOREST_PLANTATION_ID);
      map.removeSource(STATE_FOREST_PLANTATION_ID);
    };
  }, [map]);

  useEffect(() => {
    const source = map.getSource(STATE_FOREST_PLANTATION_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  const {
    clickedState,
    deactivateClickState,
    deactivateHoverState,
    hoveredState,
  } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: STATE_FOREST_PLANTATION_ID,
  });

  useInteractionFeatureState({
    getFeatureId: (properties) => properties.OBJECTID,
    clickedState,
    hoveredState,
    sourceId: STATE_FOREST_PLANTATION_ID,
  });

  return (
    <>
      <PreviewSpatialPopup onClose={deactivateHoverState} state={hoveredState}>
        State forest plantation: {hoveredState.properties?.GazettedSFNo || "—"}
      </PreviewSpatialPopup>
      <PreviewSpatialPopup
        onClose={deactivateClickState}
        state={clickedState}
        type="click"
      >
        State forest plantation: {clickedState.properties?.GazettedSFNo || "—"}
      </PreviewSpatialPopup>
    </>
  );
};

export default StateForestPlantationLayer;
