import React, { useEffect } from "react";
import { MapLevel, terrainLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import useMapContext from "../Map/useMapContext";

const TERRAIN_ID = "mapbox-dem";
const HILL_SHADING_ID = "hill-shading";

const TerrainComponent: React.FC = () => {
  // TODO: We need additional work to support Terrain with MapLibre
  const map = useMapContext("mapbox");

  useEffect(() => {
    if (!map.getSource(TERRAIN_ID)) {
      map.addSource(TERRAIN_ID, {
        type: "raster-dem",
        url: "mapbox://mapbox.mapbox-terrain-dem-v1",
        tileSize: 512,
        maxzoom: 14,
      });
    }

    map.setTerrain({ source: TERRAIN_ID, exaggeration: 1.0 });

    map.addLayer(
      {
        id: HILL_SHADING_ID,
        source: TERRAIN_ID,
        type: "hillshade",
      },
      MapLevel.FS_BACKGROUND,
    );

    return () => {
      map.resetNorthPitch();
      map.setTerrain(null);
      map.removeLayer(HILL_SHADING_ID);
      map.removeSource(TERRAIN_ID);
    };
  }, [map]);

  return null;
};

const Terrain: React.FC = () => {
  const { isLayerActive } = useActiveLayersContext();
  const terrainVisibility = isLayerActive(terrainLayer.id);

  if (!terrainVisibility) {
    return null;
  }

  return <TerrainComponent />;
};

export default Terrain;
