import { Text } from "@app/design-system";
import type { RtaIncidentInteractionState } from "../../map/RtaIncidents/interactions";
import type { MapPopupType } from "../SpatialPopup/MapPopup";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import RtaIncidentPopupDetail from "./RtaIncidentPopupDetail";

interface RtaIncidentPopupProps {
  "data-testid"?: string;
  onClose: () => void;
  state: RtaIncidentInteractionState;
  type: MapPopupType;
}

const RtaIncidentPopup = ({
  "data-testid": dataTestId,
  onClose,
  state: { id: featureId, isActive, properties },
  type,
}: RtaIncidentPopupProps) => {
  return (
    <SpatialPopup
      isOpen={isActive}
      onClose={onClose}
      popupConfig={{
        id: featureId,
        offset: 32,
        type,
      }}
      lngLat={properties?.lngLat}
    >
      {properties && (
        <MapPopupView
          data-testid={dataTestId}
          hasClose={type === "click"}
          header={
            <Text size="eyebrowDefault" variant="weak">
              {properties.data.mainCategory}
            </Text>
          }
          onClose={onClose}
        >
          <RtaIncidentPopupDetail properties={properties} />
        </MapPopupView>
      )}
    </SpatialPopup>
  );
};

export default RtaIncidentPopup;
