import type { Feature } from "geojson";
import type { LngLatLike } from "mapbox-gl";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isPointFeature } from "../types";

export interface SiteInformationData {
  BelowPoint01FibresPerML: string;
  Chrysotile: string;
  foundAddress: string;
  Status: string;
  TestDate: string;
}

export function getPropertiesFromFeature(
  feature: Feature,
): SiteInformationInteractionProperties | null {
  if (!isPointFeature(feature)) {
    return null;
  }

  const lngLat = feature.geometry.coordinates as LngLatLike;

  return {
    data: {
      BelowPoint01FibresPerML:
        feature.properties?.BelowPoint01FibresPerML ?? "",
      Chrysotile: feature.properties?.Chrysotile ?? "",
      foundAddress: feature.properties?.foundAddress ?? "",
      Status: feature.properties?.Status ?? "",
      TestDate: feature.properties?.TestDate ?? "",
    },
    lngLat,
  };
}

export interface SiteInformationInteractionProperties
  extends FeatureInteractionProperties {
  data: SiteInformationData;
}

export type SiteInformationInteractionState =
  FeatureInteractionState<SiteInformationInteractionProperties>;
